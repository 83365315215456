import React, { useState, useEffect } from 'react';
import './Admin.css'; // We'll create this file later for styling
import { ethers } from 'ethers';
import Spinner from './Spinner';
/* global BigInt */
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function PendingClaimsTable({ claims, onPay, setIsProcessing }) {
  const [selectedClaims, setSelectedClaims] = useState({});

  const handleSelectAll = (e) => {
    const newSelectedClaims = {};
    claims.forEach((_, index) => {
      newSelectedClaims[index] = e.target.checked;
    });
    setSelectedClaims(newSelectedClaims);
  };

  const handleSelectClaim = (index) => {
    setSelectedClaims(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  const handlePay = () => {
    const selectedClaimsData = claims.filter((_, index) => selectedClaims[index]);
    if (selectedClaimsData.length === 0) {
      alert("No claims selected");
      return;
    }
    setIsProcessing(true);
    onPay(selectedClaimsData);
  };

  const allSelected = claims.length > 0 && 
    Object.values(selectedClaims).filter(Boolean).length === claims.length;

  return (
    <>
      <table className="claims-table">
        <thead>
          <tr>
            <th>Select</th>
            <th>Substrate Address</th>
            <th>EVM Address</th>
            <th>Claim Amount</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {claims.map((claim, index) => (
            <tr key={index}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedClaims[index] || false}
                  onChange={() => handleSelectClaim(index)}
                />
              </td>
              <td>{claim.substrateAddress}</td>
              <td>{claim.evmAddress}</td>
              <td>{claim.formattedAmount ? claim.formattedAmount : 'N/A'}</td>
              <td>{claim.status ? 'Paid' : 'Pending'}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="admin-controls">
        <div className="selected-count">
          Selected: {Object.values(selectedClaims).filter(Boolean).length}
        </div>
        <button onClick={(e) => handleSelectAll({ target: { checked: !allSelected } })}>
          {allSelected ? 'Deselect All' : 'Select All'}
        </button>
        <button onClick={handlePay} className="pay-button">Pay Selected Claims</button>
      </div>
    </>
  );
}

function CompletedClaimsTable({ claims }) {
  return (
    <table className="claims-table">
      <thead>
        <tr>
          <th>Substrate Address</th>
          <th>EVM Address</th>
          <th>Claim Amount</th>
          <th>Transaction Hash</th>
        </tr>
      </thead>
      <tbody>
        {claims.map((claim, index) => (
          <tr key={index}>
            <td>{claim.substrateAddress}</td>
            <td>{claim.evmAddress}</td>
            <td>{claim.formattedAmount ? claim.formattedAmount : 'N/A'}</td>
            <td>
              <a href={`https://basescan.org/tx/${claim.transactionHash}`} target="_blank" rel="noopener noreferrer">
                {claim.transactionHash.substring(0, 10)}...
              </a>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

function Admin() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [password, setPassword] = useState('');
  const [claims, setClaims] = useState({ pending: [], completed: [] });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('pending');
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    if (isLoggedIn) {
      fetchClaims();
    } else {
      setLoading(false);
    }
  }, [isLoggedIn]);

  const handleLogin = (e) => {
    e.preventDefault();
    if (password === 'Yw2TKlN1B6') {
      setIsLoggedIn(true);
    } else {
      setError('Incorrect password');
    }
  };

  const fetchClaims = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/pending_claims`);
      if (!response.ok) {
        throw new Error('Failed to fetch claims');
      }
      const data = await response.json();
      setClaims({
        pending: data.filter(claim => !claim.transactionHash),
        completed: data.filter(claim => claim.transactionHash)
      });
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const handlePay = async (selectedClaimsData) => {
    setIsProcessing(true);
    try {
      // Check if MetaMask is installed
      if (typeof window.ethereum === 'undefined') {
        alert('Please install MetaMask to process payments.');
        return;
      }

      const tokenAddress = '0xc7b7a8da6fd290dd945ce104aff976c7665f6a5e';
      const multiSenderAddress = '0x0dcf73bcb5b7660a6d0fc44b9fb4a302f05729b1';
      const tokenABI = [
        "function approve(address spender, uint256 amount) returns (bool)",
        "function decimals() view returns (uint8)"
      ];
      const multiSenderABI = [
        "function sendTokensAndEth(address tokenAddress, address[] calldata recipients, uint256[] calldata amounts, uint256 ethAmount) external payable"
      ];

      // Request account access
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const tokenContract = new ethers.Contract(tokenAddress, tokenABI, signer);
      const multiSenderContract = new ethers.Contract(multiSenderAddress, multiSenderABI, signer);

      // Get token decimals
      const decimals = await tokenContract.decimals();

      // Prepare arrays for batch transfer
      const recipientMap = new Map();

      for (const claim of selectedClaimsData) {
        const amount = ethers.parseUnits(claim.formattedAmount, decimals);
        if (recipientMap.has(claim.evmAddress)) {
          recipientMap.set(claim.evmAddress, {
            amount: recipientMap.get(claim.evmAddress).amount + amount,
            claims: [...recipientMap.get(claim.evmAddress).claims, claim]
          });
        } else {
          recipientMap.set(claim.evmAddress, { amount, claims: [claim] });
        }
      }

      const recipients = Array.from(recipientMap.keys());
      const amounts = Array.from(recipientMap.values()).map(v => v.amount);
      const totalAmount = amounts.reduce((a, b) => a + b, ethers.parseUnits('0', decimals));

      // Calculate ethAmount: 0.00001 ETH per unique recipient
      const ethPerRecipient = ethers.parseEther("0.00001");
      const ethAmount = ethPerRecipient * BigInt(recipients.length);

      console.log(`Sending ${ethers.formatEther(ethAmount)} ETH in total`);

      // Approve MultiSender contract to spend tokens
      const approveTx = await tokenContract.approve(multiSenderAddress, totalAmount);
      await approveTx.wait();

      console.log("Approved MultiSender contract to spend tokens");

      // Call MultiSender contract
      console.log("Calling MultiSender contract");
      console.log("Eth Amount:", ethers.formatEther(ethAmount));
      console.log("Recipients:", recipients);
      console.log("Amounts:", amounts);
      console.log("Token Address:", tokenAddress);
      console.log("MultiSender Address:", multiSenderAddress);
      const tx = await multiSenderContract.sendTokensAndEth(
        tokenAddress,
        recipients,
        amounts,
        ethAmount,
        { value: ethAmount }
      );

      console.log("Processing batch payment");
      console.log("Transaction Hash:", tx.hash);
      const receipt = await tx.wait();
      console.log("Transaction mined:", receipt);

      // Prepare payment data for API call
      const paymentData = Array.from(recipientMap.entries()).flatMap(([evmAddress, { claims }]) =>
        claims.map(claim => {
          let checksumAddress;
          try {
            checksumAddress = ethers.getAddress(evmAddress); // Converts to checksum format
          } catch (err) {
            console.error(`Failed to convert address ${evmAddress} to checksum format:`, err);
            throw new Error(`Invalid EVM address format: ${evmAddress}`);
          }
          
          return {
            substrateAddress: claim.substrateAddress,
            evmAddress: checksumAddress,
            transactionHash: tx.hash,
          };
        })
      );

      // Call the payment_processed API
      const response = await fetch(`${API_BASE_URL}/payment_processed`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(paymentData),
      });

      if (!response.ok) {
        throw new Error('Failed to update payment status');
      }

      const results = await response.json();
      console.log('Payment status update results:', results);

      alert("Batch payment processed and status updated successfully!");
      // After successful payment:
      await fetchClaims();
    } catch (err) {
      console.error("Payment or status update failed:", err);
      alert("Payment or status update failed. Please try again.");
    } finally {
      setIsProcessing(false);
    }
  };

  if (!isLoggedIn) {
    return (
      <div className="Admin" style={{ color: 'white' }}>
        <h2>Admin Login</h2>
        <form onSubmit={handleLogin}>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter password"
            required
          />
          <button type="submit">Login</button>
        </form>
        {error && <p className="error">{error}</p>}
      </div>
    );
  }

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="Admin">
      {isProcessing && <Spinner />}
      <h1>Admin Dashboard</h1>
      <button onClick={() => setIsLoggedIn(false)} className="logout-button">Logout</button>
      
      <div className="tabs">
        <button 
          className={`tab ${activeTab === 'pending' ? 'active' : ''}`}
          onClick={() => setActiveTab('pending')}
        >
          Pending Claims
        </button>
        <button 
          className={`tab ${activeTab === 'completed' ? 'active' : ''}`}
          onClick={() => setActiveTab('completed')}
        >
          Completed Claims
        </button>
      </div>

      {activeTab === 'pending' ? (
        <PendingClaimsTable 
          claims={claims.pending} 
          onPay={handlePay}
          setIsProcessing={setIsProcessing}
        />
      ) : (
        <CompletedClaimsTable claims={claims.completed} />
      )}
    </div>
  );
}

export default Admin;
