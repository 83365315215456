import React, { useState, useEffect } from 'react';
import {
  web3Accounts,
  web3Enable,
  web3FromAddress,
  web3FromSource,
} from '@polkadot/extension-dapp';
import { stringToHex } from '@polkadot/util';
import './App.css';
import baseNetworkImage from './basenetwork.png';
import bbbTokenImage from './import.png';
import addTokenImage from './tokenaddress.png';
import addTokenImage2 from './tokenfinal.png';
import coinbaseNetworkImage from './basewallet.png';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function App() {
  const [walletInstalled, setWalletInstalled] = useState(false);
  const [walletAddress, setWalletAddress] = useState(null);
  const [claimStatus, setClaimStatus] = useState('');
  const [baseAddress, setBaseAddress] = useState('');
  const [claimAmount, setClaimAmount] = useState(null);
  const [polkadotWalletInstalled, setPolkadotWalletInstalled] = useState(false);

  useEffect(() => {
    // Check if the Bitgreen Wallet and Polkadot.js extension are installed
    window.addEventListener('load', () => {
      const bitgreenWallet = window.injectedWeb3?.['bitgreen-wallet-js'];
      const polkadotWallet = window.injectedWeb3?.['polkadot-js'];
      if (bitgreenWallet) {
        setWalletInstalled(true);
      }
      // if (polkadotWallet) {
      //   setPolkadotWalletInstalled(true);
      // }
    });
  }, []);

  const connectWallet = async () => {
    if (!walletInstalled) {
      alert('Bitgreen Wallet is not installed');
      return;
    }

    try {
      const bitgreenWallet = window.injectedWeb3['bitgreen-wallet-js'];
      const response = await bitgreenWallet.signIn();
      if (response.success) {
        const address = response.data.address;
        setWalletAddress(address);

        // Call the Express server to check claim
        const claimResponse = await fetch(`${API_BASE_URL}/check_claim`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ substrateAddress: address }),
        });

        const claimData = await claimResponse.text();
        setClaimStatus(claimData);

        // Check if the claimData contains a claim amount
        const claimAmountMatch = claimData.match(
          /Claim amount for .* is (\d+)/
        );
        if (claimAmountMatch) {
          setClaimAmount(claimAmountMatch[1]);
        } else {
          setClaimAmount(null);
        }
      } else {
        alert('Failed to connect to Bitgreen Wallet');
      }
    } catch (error) {
      console.error('Error connecting to Bitgreen Wallet:', error);
      alert('Error connecting to Bitgreen Wallet');
    }
  };

  const connectPolkadotWallet = async () => {
    const allInjected = await web3Enable('my cool dapp');
    const allAccounts = await web3Accounts();

    if (allAccounts.length === 0) {
      alert('No accounts found in Polkadot.js extension');
      return;
    }

    try {
      setPolkadotWalletInstalled(true);
      if (allAccounts.length > 0) {
        console.log(allAccounts);
        const address = allAccounts[0].address;
        setWalletAddress(address);

        // Call the Express server to check claim
        const claimResponse = await fetch(`${API_BASE_URL}/check_claim`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ substrateAddress: address }),
        });

        const claimData = await claimResponse.text();
        setClaimStatus(claimData);

        console.log(claimData);

        // Check if the claimData contains a claim amount
        const claimAmountMatch = claimData.match(
          /Claim amount for .* is (\d+)/
        );
        if (claimAmountMatch) {
          setClaimAmount(claimAmountMatch[1]);
        } else {
          setClaimAmount(null);
        }
      } else {
        alert('No accounts found in Polkadot.js extension');
      }
    } catch (error) {
      console.error('Error connecting to Polkadot.js extension:', error);
      alert('Error connecting to Polkadot.js extension');
    }
  };

  const signWithPolkadotWallet = async () => {
    const allInjected = await web3Enable('my cool dapp');
    const allAccounts = await web3Accounts();
    const account = allAccounts[0];
    const injector = await web3FromSource(account.meta.source);
    const signRaw = injector?.signer?.signRaw;
    const message = `Register claim for ${walletAddress}`;

    if (!!signRaw) {
      // after making sure that signRaw is defined
      // we can use it to sign our message
      const { signature } = await signRaw({
        address: account.address,
        data: stringToHex(message),
        type: 'bytes',
      });

      return {
        message: stringToHex(message),
        signature: signature,
      };
    }
  };

  const signWithBitgreenWallet = async () => {
    const bitgreenWallet = window.injectedWeb3['bitgreen-wallet-js'];
    const signResponse = await bitgreenWallet.signIn();
    console.log(signResponse);
    return signResponse.success
      ? {
        message: signResponse.data.message,
        signature: signResponse.data.signature,
      }
      : null;
  };

  const registerClaim = async () => {
    if (!baseAddress) {
      alert('Base address is required');
      return;
    }

    try {
      const signResponse = polkadotWalletInstalled
        ? await signWithPolkadotWallet()
        : await signWithBitgreenWallet();

      if (!signResponse) {
        alert('Failed to sign the message');
        return;
      }

      const signature = signResponse.signature;
      const message = signResponse.message;

      const response = await fetch(`${API_BASE_URL}/register_claim`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          substrateAddress: walletAddress,
          evmAddress: baseAddress,
          message,
          signature,
        }),
      });

      const result = await response.text();
      alert(result);
    } catch (error) {
      console.error('Error registering claim:', error);
      alert('Error registering claim');
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <div className="intro">
          <img
            src="/bitgreen-logo-dark.svg"
            className="logo"
            alt="Bitgreen logo"
          />
          <h1>How to setup your EVM wallet</h1>
          <p className="App-description" style={{ textAlign: 'left' }}>
            Short guide on how to setup your EVM wallet to receive your Bitgreen tokens.
            This guide lists the most common EVM wallets (Metamask and Coinbase) and how to connect them, this is not an exhaustive list and you are free to use any other EVM wallet.
          </p>
        </div>

        <div className="App-info">

        <div className="prerequisites">
        <p> <h2>Important Info: </h2>
        BBB Token Address : <a href='https://basescan.org/address/0xc7b7a8da6fd290dd945ce104aff976c7665f6a5e'>0xc7b7a8da6fd290dd945ce104aff976c7665f6a5e</a> <br />
        Supported Network : Base
        </p>
        <br />
        </div>

        

          <div className="prerequisites">
  <h2>Setup your Metamask Wallet: </h2>
  <ul>
    <li>
      <strong>Install:</strong> Head to the <a href='https://metamask.io/download/'>Metamask website</a> and install the wallet.
    </li>
    <li>
      <strong>Create a new wallet:</strong> Click on "Create a Wallet" and follow the prompts to create a new wallet.
    </li>
    <li>
      <strong>Backup your wallet:</strong> Make sure to save your wallet information in a secure location.
    </li>
    <li>
      <strong>Connect to the Base network:</strong> Click on the network dropdown in the top right corner of the wallet, select "Base Mainnet" from the list of networks.
      <br />
      <img src={baseNetworkImage} alt='Base network selection in MetaMask' />
      <br />
    </li>
      <li>
        <strong>Add BBB Tokens:</strong> Click on the "Tokens" tab, then click on the "Import Tokens" button, select "Custom Token" and use the token contract address: `0xc7b7a8da6fd290dd945ce104aff976c7665f6a5e` and add the token to your wallet.
        <br />
        <img src={bbbTokenImage} alt='Adding BBB Token to MetaMask' />
        <br />
        <br />
        <img src={addTokenImage} alt='Adding BBB Token to MetaMask' />
        <br />
        <img src={addTokenImage2} alt='Adding BBB Token to MetaMask' />
        <br />
    </li>
    <li>
      Finally copy your wallet address and paste it when asked in the claim page. You should see a "copy to clipboard" icon on the right of the address.
    </li>
            </ul>
          </div>

          <div className="prerequisites">
  <h2>Setup your Coinbase Wallet: </h2>
  <ul>
    <li>
      <strong>Install:</strong> Head to the <a href='https://www.coinbase.com/en-gb/wallet/downloads/'>Coinbase wallet website</a> and install the wallet.
    </li>
    <li>
      <strong>Create a new wallet:</strong> Click on "Create a Wallet" and follow the prompts to create a new wallet.
    </li>
    <li>
      <strong>Backup your wallet:</strong> Make sure to save your wallet information in a secure location.
    </li>
    <li>
      <strong>Connect to the Base network:</strong> Click on the settings cog, then select "Networks" and select "Base" from the list of Mainnet networks.
      <br />
      <img src={coinbaseNetworkImage} alt='Base network selection in Coinbase' />
      <br />
    </li>
    <li>
      Finally copy your wallet address and paste it when asked in the claim page. You can get this by clicking on "Receive" and then clicking on "Your Ethereum Address".
    </li>
            </ul>
          </div>
          
        </div>
      </header>

      {/* <footer className="App-footer">
        <p>
          Contact <a href="mailto:wallet@bitgreen.org">wallet@bitgreen.org</a>{' '}
          for support.
        </p>
        <p>© 2024 Bitgreen. All rights reserved.</p>
      </footer> */}
    </div>
  );
}

export default App;
