import React, { useState, useEffect } from 'react';
import {
  web3Accounts,
  web3Enable,
  web3FromAddress,
  web3FromSource,
} from '@polkadot/extension-dapp';
import { stringToHex } from '@polkadot/util';
import './App.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function App() {
  const [walletInstalled, setWalletInstalled] = useState(false);
  const [walletAddress, setWalletAddress] = useState(null);
  const [claimStatus, setClaimStatus] = useState('');
  const [baseAddress, setBaseAddress] = useState('');
  const [claimAmount, setClaimAmount] = useState(null);
  const [isRepublic, setIsRepublic] = useState(false);
  const [polkadotWalletInstalled, setPolkadotWalletInstalled] = useState(false);

  useEffect(() => {
    // Check if the Bitgreen Wallet and Polkadot.js extension are installed
    window.addEventListener('load', () => {
      const bitgreenWallet = window.injectedWeb3?.['bitgreen-wallet-js'];
      const polkadotWallet = window.injectedWeb3?.['polkadot-js'];
      if (bitgreenWallet) {
        setWalletInstalled(true);
      }
      // if (polkadotWallet) {
      //   setPolkadotWalletInstalled(true);
      // }
    });
  }, []);

  const connectWallet = async () => {
    if (!walletInstalled) {
      alert('Bitgreen Wallet is not installed');
      return;
    }

    try {
      const bitgreenWallet = window.injectedWeb3['bitgreen-wallet-js'];
      const response = await bitgreenWallet.signIn();
      if (response.success) {
        const address = response.data.address;
        setWalletAddress(address);

        // Call the Express server to check claim
        const claimResponse = await fetch(`${API_BASE_URL}/check_claim`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ substrateAddress: address }),
        });

        const claimData = await claimResponse.json();
        console.log(claimData);
        setClaimStatus(true);
        setClaimAmount(claimData.amount);
        if (claimData.isRepublic) {
          setIsRepublic(true);
        }
      } else {
        alert('Failed to connect to Bitgreen Wallet');
      }
    } catch (error) {
      console.error('Error connecting to Bitgreen Wallet:', error);
      alert('Error connecting to Bitgreen Wallet');
    }
  };

  const connectPolkadotWallet = async () => {
    const allInjected = await web3Enable('Bitgreen Migration');
    const allAccounts = await web3Accounts();

    if (allAccounts.length === 0) {
      alert('No accounts found in Polkadot.js extension');
      return;
    }

    try {
      setPolkadotWalletInstalled(true);
      if (allAccounts.length > 0) {
        console.log(allAccounts);
        const address = allAccounts[0].address;
        setWalletAddress(address);

        // Call the Express server to check claim
        const claimResponse = await fetch(`${API_BASE_URL}/check_claim`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ substrateAddress: address }),
        });

        console.log(claimResponse);
        const claimData = await claimResponse.json();
        console.log(claimData);
        if (claimData.alreadyClaimed) {
          setClaimStatus(false);
        } else {
          setClaimStatus(true);
          setClaimAmount(claimData.amount);
          if (claimData.isRepublic) {
            setIsRepublic(true);
          }
        }
      } else {
        alert('Failed to connect to Bitgreen Wallet');
      }
    } catch (error) {
      console.error('Error connecting to Polkadot.js extension:', error);
      alert('Error connecting to Polkadot.js extension');
    }
  };

  const signWithPolkadotWallet = async () => {
    const allInjected = await web3Enable('Bitgreen Migration');
    const allAccounts = await web3Accounts();
    const account = allAccounts[0];
    const injector = await web3FromSource(account.meta.source);
    const signRaw = injector?.signer?.signRaw;
    const message = `Register claim for ${walletAddress}`;

    if (!!signRaw) {
      // after making sure that signRaw is defined
      // we can use it to sign our message
      const { signature } = await signRaw({
        address: account.address,
        data: stringToHex(message),
        type: 'bytes',
      });

      return {
        message: stringToHex(message),
        signature: signature,
      };
    }
  };

  const signWithBitgreenWallet = async () => {
    const bitgreenWallet = window.injectedWeb3['bitgreen-wallet-js'];
    const signResponse = await bitgreenWallet.signIn();
    console.log(signResponse);
    return signResponse.success
      ? {
        message: signResponse.data.message,
        signature: signResponse.data.signature,
      }
      : null;
  };

  const registerClaim = async () => {
    if (!baseAddress) {
      alert('Base address is required');
      return;
    }

      // Validate the Base address
    const evmAddressRegex = /^0x[a-fA-F0-9]{40}$/;
    if (!evmAddressRegex.test(baseAddress)) {
      alert('Invalid Base address. Please enter a valid EVM address.');
        return;
      }


    try {
      const signResponse = polkadotWalletInstalled
        ? await signWithPolkadotWallet()
        : await signWithBitgreenWallet();

      if (!signResponse) {
        alert('Failed to sign the message');
        return;
      }

      const signature = signResponse.signature;
      const message = signResponse.message;

      const response = await fetch(`${API_BASE_URL}/register_claim`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          substrateAddress: walletAddress,
          evmAddress: baseAddress,
          message,
          signature,
        }),
      });

      const result = await response.text();
      alert(result);
    } catch (error) {
      console.error('Error registering claim:', error);
      alert('Error registering claim');
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <div className="intro">
        <img
            src="/bitgreen-logo-dark.svg"
            className="logo"
            alt="Bitgreen logo"
          />
        </div>
        <div className="prerequisites">
          
          <h1>Bitgreen Migration To Base Chain</h1>
          <div className="prerequisites">
            <p>
              As announced last week, Bitgreen parachain is migrating away from the Polkadot blockchain network to Base mainnet, a layer-2 network built by Coinbase atop the Ethereum blockchain. 🎉
            </p>
            <p>
              We believe that this transition will significantly enhance Bitgreen's capabilities, user experience, and prospects for growth.
            </p>
            <p>
              This website allows all users to enter their existing Bitgreen wallet address, which begins with the number '5' (five), to a Base chain wallet address, beginning with the number '0' (zero). Upon successfully entering your two addresses below, your BBB tokens will arrive in your Base wallet.
            </p>
            <br />
            <div className="note-box info">
              <p>Always ensure the token address you are interacting with is correct. This is the only BBB token address that will be used for the migration</p>
              <p>
                BBB Token address: <strong>0xc7b7a8DA6fD290Dd945cE104AFf976C7665F6a5E</strong>
              </p>
              <p>
                Link to token: <a href="https://explorer.base.org/token/0xc7b7a8DA6fD290Dd945cE104AFf976C7665F6a5E" target="_blank" rel="noopener noreferrer">Base Explorer</a>
              </p>
            </div>
            <br />
            <p className="important-note">
              <strong>
                Note: This migration is not an Airdrop nor does it include any type of reward. The migration consists of Bitgreen "changing train tracks" to a new infrastructure provider.
              </strong>
            </p>
          </div>
        </div>

        <div className="App-info">
          <div className="note-box warning">
            <p>Note : If you received your BBB tokens from Republic, your tokens will not be eligible for migrating on this website. Your tokens will be available in the Republic wallet.</p>
          </div>
          <br />

          <div className="prerequisites">
            <h2>Prerequisites:</h2>
            <ul>
              <li>
                <strong>Bitgreen:</strong> You must have either a Bitgreen or PolkadotJS wallet installed on your computer browser using Chrome, Firefox or Safari.
                If not, install one here: <a href="https://bitgreen.org/wallet" target="_blank" rel="noopener noreferrer">https://bitgreen.org/wallet</a>
              </li>
              <li>
                <strong>Base:</strong> Ensure you have a valid wallet that can receive Base network tokens. You can use Coinbase, Metamask, or Republic Wallet.
                Simple directions are located here: <a href="/metamask-setup" target="_blank" rel="noopener noreferrer">Setup Guide</a>
              </li>
            </ul>
          </div>

          <div className="steps">
            <h2>Steps:</h2>
            <ul>
              <li>
                Click on the "Connect Bitgreen Wallet" or "Connect Polkadot.js
                Wallet" button
              </li>
              <li>The portal will fetch your token details</li>
              <li>
                If you have a valid migration, you will be prompted to enter your
                Base address
              </li>
              <li>
                Click on the "Register Migration" button to register your migration
              </li>
              <li>
                Tokens will be issued to your Base address within 48 hours
              </li>
            </ul>
          </div>
        </div>

        <div className="wallet-buttons">
          <button className="App-button" onClick={connectWallet}>
            Connect Bitgreen Wallet
          </button>
          <button
            className="App-button polkadot-button"
            onClick={connectPolkadotWallet}
          >
            Connect Polkadot.js Wallet
          </button>
        </div>

        {walletAddress && <p>Connected Wallet Address: {walletAddress}</p>}

        {claimStatus && (
          <div>
            <table className="App-table">
              <thead>
                <tr>
                  <th>Address</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{walletAddress}</td>
                  <td>{claimAmount ? claimAmount : '0'}</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}

        {claimAmount && !isRepublic && (
          <div>
            <p className="App-warning">
              <strong>Warning:</strong> Make sure you control the private key to
              the Base wallet address given below. Once the BBB tokens are
              issued to this address, Bitgreen cannot revoke or refund the
              tokens. DO NOT USE AN EXCHANGE WALLET ADDRESS.
            </p>
            <input
              type="text"
              placeholder="Enter Base Address"
              value={baseAddress}
              onChange={(e) => setBaseAddress(e.target.value)}
              className="App-input"
            />
            <button className="App-button" onClick={registerClaim}>
              Register Migration
            </button>
          </div>
        )}
        {claimAmount && isRepublic && (
          <div>
            <p className="App-info">
              <strong>Notice:</strong> We see that your wallet account is tied to the Republic Platform. At the request of many Republic lenders, we will deposit your new tokens directly via the Republic wallet. Expect an email from Bitgreen once this step has been completed. There is no further action for you to take now. 
              <br />For any questions, email <a href="mailto:wallet@bitgreen.org">wallet@bitgreen.org</a>.
            </p>
          </div>
        )}

        {!claimAmount && claimStatus && (
          <div>
            <p className="App-info">
              <strong>Notice:</strong> No migration found for this address, either you have already migrated your tokens or your address is not eligible for migration, if you believe this is an error, please contact us at <a href="mailto:wallet@bitgreen.org">wallet@bitgreen.org</a>.
            </p>
          </div>
        )}
      </header>

      {/* <footer className="App-footer">
        <p>
          Contact <a href="mailto:wallet@bitgreen.org">wallet@bitgreen.org</a>{' '}
          for support.
        </p>
        <p>© 2024 Bitgreen. All rights reserved.</p>
      </footer> */}
    </div>
  );
}

export default App;
